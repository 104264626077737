import React from 'react';
import { styled } from 'linaria/react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-apollo';
import loadable from '@loadable/component';
import { Route, Switch, useLocation } from 'react-router-dom';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import Footer from './Layout/Footer/Footer';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import pageQuery from './PageQuery.gql';

import '@jetshop/ui/Theme/sanitizeCss';
import '../globalStyles';

import Megamenu from './Layout/Header/Megamenu';

import { HTML2JSON } from './utility/functions';
import { convertToMegaMenucategories } from './utility/conversions/header';

import NavigationCategoriesQuery from '../components/Layout/Header/NavigationCategories.gql';
import { theme } from './Theme';
import CategoryCustomOverview from './CategoryPage/CategoryCustomOverview';
import MaintenancePage from './MaintenancePage';
import LoadableVoyadoLookup from './Auth/Voyado/VoyadoLookup';
import { VoyadoProvider } from '@jetshop/flight-voyado';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

// const LogInPage = loadable(() => import('./Auth/LogInPage'), {
//   fallback: <LoadingPage />
// });

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableStartPage = loadable(() => import('./StartPage/MainStartPage'), {
  fallback: <LoadingPage />
});

const LoadableStartPageById = loadable(
  () => import('./StartPage/StartPageById'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableInspirationOverview = loadable(
  () => import('./Inspiration/InspirationOverviewHandler'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableInspiration = loadable(
  () => import('./Inspiration/Single/InspirationPageHandler'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPageHandler'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});

const LoadableSignUpPage = loadable(
  () => import('./Auth/Voyado/VoyadoSignup'),
  {
    fallback: <LoadingPage />
  }
);

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});

const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

const ConditionalCategoryPage = props => {
  const {
    routes: { inspiration }
  } = useShopConfig();
  return (
    <Switch>
      <Route
        path={inspiration.path}
        exact
        render={() => <LoadableInspirationOverview {...props} />}
      />
      <Route
        path={`${inspiration.path}/:path`}
        render={() => <LoadableInspiration {...props} />}
      />
      <Route render={() => <LoadableCategoryPage {...props} />} />
    </Switch>
  );
};

const Main = styled('main')`
  padding-top: 0;

  > :first-of-type:not(script):not(.hero-text.useFullWidth):not(.hero-blocks),
  > script:first-of-type + *:not(.hero-text.useFullWidth) {
    margin-top: 50px;

    ${theme.below.lg} {
      margin-top: 20px;
    }
  }
`;

function Shop() {
  const location = useLocation();
  const {
    routes: {
      offers,
      favourites,
      search,
      myPages,
      signup,
      login,
      stores,
      forgotPassword,
      resetPassword
    },
    siteConfig: { headerPageId }
  } = useShopConfig();

  // header navigation
  const { data } = useQuery(NavigationCategoriesQuery, {
    variables: {
      id: headerPageId
    }
  });
  const navigation = convertToMegaMenucategories(data?.page?.data?.items);

  let usps = [];
  let textColor;
  if (data?.page?.content) {
    const json = HTML2JSON(data.page.content);
    usps = json.usps;
  }

  const activeMegaMenu = navigation?.find(
    entry => entry.link === location.pathname
  );
  const megaMenuHasCustomOverview = activeMegaMenu?.customOverview;
  return (
    <GenericError>
      <ModalProvider>
        <LoadingBar />
        <CustomFont
          primaryFont={'Open Sans'}
          secondaryFonts={['DINEngschriftLT-Alternate']}
          injectCss={loadFontCss}
        />
        <ChannelContext.Consumer>
          {({ selectedChannel }) => (
            <Helmet
              titleTemplate="%s - Svenska Hem"
              defaultTitle="Svenska Hem"
              htmlAttributes={{
                lang: selectedChannel?.language?.culture
              }}
            />
          )}
        </ChannelContext.Consumer>
        <VoyadoProvider
          providerOptions={{
            activateOnSoftLogin: true
          }}
        >
          <ProductListProvider queries={productListQueries}>
            <Megamenu
              navigation={navigation}
              usps={usps}
              textColor={textColor}
            />
            <Main>
              <PaginationProvider defaultProductsPerPage={24}>
                <Switch>
                  <Route
                    render={() => <LoadableContentPage path={offers.path} />}
                    path={offers.path}
                  />
                  <Route exact path="/" component={LoadableStartPage} />
                  <Route path={favourites.path} component={Favourites} />
                  <Route
                    path={search.path}
                    render={props => <LoadableSearchPage {...props} />}
                  />
                  <Route path={signup.path} component={LoadableSignUpPage} />
                  <Route path={login.path} component={LoadableVoyadoLookup} />
                  <Route path={`${stores.path}/:id`} component={Store} />
                  <Route path={stores.path} component={StoreLocator} />
                  <Route path={myPages.path} component={LoadableMyPages} />
                  <Route
                    exact
                    path={forgotPassword.path}
                    component={ForgotPassword}
                  />
                  <Route
                    path={`${resetPassword.path}/:token`}
                    component={ResetPassword}
                  />
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        contentPage={LoadableContentPage}
                        pageQuery={pageQuery}
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        categoryPage={LoadableCategoryPage}
                        StartPage={LoadableStartPageById}
                        {...props}
                      />
                    )}
                  />
                  {megaMenuHasCustomOverview && (
                    <Route
                      component={() => (
                        <CategoryCustomOverview {...activeMegaMenu} />
                      )}
                    />
                  )}
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={ConditionalCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Main>
            <Footer />
          </ProductListProvider>
        </VoyadoProvider>
        <ModalRoot />
        <ScrollRestorationHandler
          ignoreForRouteTypes={[
            'sortOrderChange',
            'filterChange',
            'paginationChange'
          ]}
        />
      </ModalProvider>
    </GenericError>
  );
}

const AppEntry = () => {
  const { siteConfig } = useShopConfig();
  return siteConfig.downForMaintenance ? <MaintenancePage /> : <Shop />;
};

export default AppEntry;
