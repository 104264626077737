import React from 'react';
import { Formik } from 'formik';
import Input from '../../Forms/Input';
import t from '@jetshop/intl';
import { CustomerStatusHeaderText, Title } from './VoyadoLookup';
import { styled } from 'linaria/react';
import { useIntl } from '@jetshop/intl';
import RequiredAsterisk from '../../Forms/RequiredAsterisk';
import Button, { TrendLink } from '../../ui/Button';

export const InputLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  color: #4b543c;
  font-weight: 400;
  line-height: 21.6px;

  svg {
    margin: 0 0 10px 1px;
  }
`;

function InputLabel() {
  return (
    <InputLabelWrapper>
      <span>{t('E-mail address')}</span>
      <RequiredAsterisk />
    </InputLabelWrapper>
  );
}

const RequiredText = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  color: #8a3c10;
  margin: 0.75rem 0 0;

  svg {
    margin: 0 1px 10px 0 !important;
  }
`;

export const RequiredFieldsText = ({ className, style }) => (
  <RequiredText className={className ?? ''} style={style ? style : {}}>
    <RequiredAsterisk />
    <span>{t('Required fields')}</span>
  </RequiredText>
);

export const LoginButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2rem;
`;

const StyledForm = styled('form')`
  max-width: 30rem;

  .voyado-field {
    margin-bottom: 0;
  }
`;

const ErrorContainer = styled.div`
  p {
    margin: 0 0 0.25rem;
  }

  a {
    margin-top: 1.5rem;
  }
`;

export const ExternalLookupField = ({ lookup, children, setEnteredEmail }) => {
  const t = useIntl();

  // FILTER OUT ERRONEOUS 'FALSE' CHILDREN RETURNED
  children = children.filter(child => {
    return !!child;
  });

  return (
    <Formik
      initialValues={{ lookupKey: '' }}
      onSubmit={values => {
        setEnteredEmail(values.lookupKey.trim());
        lookup(values.lookupKey);
      }}
    >
      {({ values, isSubmitting, handleChange, handleBlur, handleSubmit }) => {
        return (
          <>
            <StyledForm onSubmit={handleSubmit}>
              <Title>{t('Fill out your e-mail to log in')}</Title>
              <CustomerStatusHeaderText>
                {t(
                  'Enter your { lookupKey } and we will check if you are an existing member or not.',
                  { lookupKey: 'e-mail' }
                )}
              </CustomerStatusHeaderText>
              <Input
                label={<InputLabel />}
                name="lookupKey"
                type="email"
                placeholder={t('Enter email address')}
                onChange={handleChange}
                onBlur={handleBlur}
                wrapperClassName="voyado-field"
              />
              <RequiredFieldsText />
              <LoginButtonWrapper>
                {!children?.length > 0 && (
                  <Button
                    style={{
                      width: '100%',
                      textTransform: 'uppercase',
                      maxWidth: '100%'
                    }}
                    dark={true}
                    data-testid="commit-lookup"
                    type="submit"
                    disabled={isSubmitting || values.lookupKey.length === 0}
                    loadingText={t('Checking customer status...')}
                    loading={isSubmitting}
                  >
                    {t('Continue')}
                  </Button>
                )}
              </LoginButtonWrapper>
              {/* Start error message */}
              {children?.length > 0 && (
                <ErrorContainer>
                  <p>{t('Something went wrong')}:</p>
                  {children}
                  <TrendLink to={'/mina-sidor'}>{t('Try again')}</TrendLink>
                </ErrorContainer>
              )}

              {/* End error message */}
            </StyledForm>
          </>
        );
      }}
    </Formik>
  );
};
