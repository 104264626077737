import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import UIButton from '@jetshop/ui/Button';
import Link from '../utility/Link';
import { theme } from '../Theme';
import { isRelativeUrl } from '@jetshop/core/helpers/isRelativeUrl';

/*==============================================================================
  # Styles
==============================================================================*/

export const ButtonStyle = `
  display: inline-block;
  width: auto;
  height: auto;
  border: 1px solid currentColor;
  color: ${theme.colors.white};
  background: ${theme.colors.slategrey};
  border-color: ${theme.colors.slategrey};
  text-align: center;
  text-decoration: none !important;
  overflow: hidden;
  box-shadow: 0;
  font-family: ${theme.fonts.heading};
  cursor: pointer;
  transition: all 450ms ease;
  letter-spacing: 0.5px;

  &:disabled,
  &:hover {
    color: ${theme.colors.slategrey};
    background: transparent;
    border-color: ${theme.colors.slategrey};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &.isActive {
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.white};
  }

  &.white {
    color: ${theme.colors.black};
    border-color: ${theme.colors.white};
    background: ${theme.colors.white};
  }

  &.white:hover {
    color: ${theme.colors.white};
    border-color: ${theme.colors.white};
    background: transparent;
  }

  &.dark {
    color: ${theme.colors.white};
  }

  &.dark {
    &:disabled,
    &:hover {
      color: ${theme.colors.white};
      border-color: ${theme.colors.black};
      background: ${theme.colors.black};
    }

    &.isActive {
      color: ${theme.colors.white};
      background: ${theme.colors.black};
      border-color: ${theme.colors.black};
    }
  }
`;

const Wrapper = styled('div')`
  position: relative;
  min-width: 120px;
  padding: 8px 22px;
`;

const Foreground = styled('span')`
  position: relative;
`;

const StyledButton = styled(UIButton)`
  ${ButtonStyle}

  &:focus,
  .inner:focus {
    outline: none;
  }

  &:focus > .inner {
    box-shadow: 0 0 2px 2px #51a7e8;
  }
`;

const StyledLink = styled(Link)`
  ${ButtonStyle};
`;

const TrendABase = styled('a')`
  /* ${ButtonStyle}; */
  /* text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  color: #202416;
  font-weight: 400; */
`;

const TrendLinkBase = styled(Link)`
  // /* ${ButtonStyle}; */
  // /* text-decoration: none;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 54px;
  // color: #202416;
  // font-weight: 400; */
  // background-color: transparent;
  // padding: 12px 2rem;
  // height: 3rem;
  // width: 250px;
  // text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // font-size: 1.125rem;
  // font-weight: 400;
  // line-height: 21.6px;
  // text-decoration: none;
  // /* margin-right: 40px; */

  //position: relative;
  //border-radius: 40px;
  //-moz-border-radius: 40px;
  //-webkit-border-radius: 40px;

  &:hover {
    //transition: border-color 0.3s ease;
    //border-color: transparent;
  }

  :before {
    content: '';
    position: absolute;
    top: -7px; // border: 2px + offset: 1px
    right: -7px; // border: 2px + offset: 1px
    bottom: -7px; // border: 2px + offset: 1px
    left: -7px; // border: 2px + offset: 1px
    border: 1px solid ${theme.colors.black};
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
  }

  ${theme.below.lg} {
    width: 200px;
  }

  ${theme.below.sm} {
    font-size: 0.875rem;
    width: 160px;
    height: 40px;
    margin-top: 15px;
  }
`;

export function TrendLink({ to, ...props }) {
  return typeof to === 'string' && !isRelativeUrl(to) ? (
    <TrendABase href={to} {...props} />
  ) : (
    <TrendLinkBase to={to} {...props} />
  );
}

/*==============================================================================
  # Main Button
==============================================================================*/

export const ButtonInner = ({ children }) => (
  <Wrapper className="inner" tabIndex="-1">
    <Foreground>{children}</Foreground>
  </Wrapper>
);

export const ButtonLink = ({
  children,
  className,
  dark,
  grey,
  white,
  isActive,
  ...props
}) => {
  let classes = className;
  if (dark) classes += ' dark';
  // if (grey) classes += ' grey';
  if (isActive) classes += ' isActive';
  if (white) classes += ' white';

  return (
    <StyledLink className={classes} {...props}>
      <ButtonInner>{children}</ButtonInner>
    </StyledLink>
  );
};

const Button = ({
  children,
  loading,
  loadingText = t('Hold on a moment...'),
  className,
  dark,
  grey,
  white,
  isActive,
  ...props
}) => {
  let classes = className;
  if (dark) classes += ' dark';
  // if (grey) classes += ' grey';
  if (isActive) classes += ' isActive';
  if (white) classes += ' white';

  return (
    <StyledButton loading={loading} className={classes} {...props}>
      <ButtonInner>{loading ? loadingText : children}</ButtonInner>
    </StyledButton>
  );
};

export default Button;
