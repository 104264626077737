import React from 'react';
import { ReactComponent as Miscellaneous } from '../../svg/Miscellaneous.svg';

const RequiredAsterisk = () => (
  <Miscellaneous
    style={{
      color: '#8a3c10',
      marginLeft: '1px'
    }}
  />
);

export default RequiredAsterisk;
